<div class="content">
  @if (loggedIn$()) {
    <sla-sidenav />
  }
  <main [class]="loggedIn$() ? 'logged-in' : ''">
    @if (!loading$()) {
      <router-outlet />
    } @else {
      <sla-loader />
    }
  </main>
</div>
@if (loggedIn$()) {
  <sla-bottom-nav />
}
