import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoadingService } from './core/services/loading.service';
import { LoaderComponent } from './core/components/loader/loader.component';
import { SidenavComponent } from './core/components/sidenav/sidenav.component';
import { AuthService } from './core/services/auth.service';
import { BottomNavComponent } from './core/components/bottom-nav/bottom-nav.component';

@Component({
  selector: 'sla-root',
  standalone: true,
  imports: [
    RouterOutlet,
    LoaderComponent,
    SidenavComponent,
    BottomNavComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  loggedIn$ = inject(AuthService).loggedIn;
  loading$ = inject(LoadingService).loading;
}
