<a class="nav-item logo" routerLink="/">
  <img class="logo-img" src="assets/logo/sirlandlord.webp" />
</a>

<a class="nav-item" routerLink="/">
  <sla-svg-icon src="home" />
</a>
<a class="nav-item" routerLink="/account">
  <sla-svg-icon src="account_circle" />
</a>
